import { useMutation, useQueryClient } from 'react-query';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';

import { GetUsersResponse, InviteUserRequestData } from '../types';

export const useInviteUser = () => {
  const client = createHttpClient();
  const queryClient = useQueryClient();

  const queryKey = QUERY_KEYS.USER.USER_LIST;

  const mutation = useMutation(
    ({
      data,
      workspaceId,
    }: {
      data: InviteUserRequestData;
      workspaceId: number;
    }) => {
      const {
        name,
        email,
        role: { id: roleId },
      } = data;

      return client<any>({
        method: HttpMethod.POST,
        url: END_POINTS.USER.INVITE_USER(workspaceId),
        data: {
          name,
          email,
          roleId,
        },
      });
    },
    {
      onSuccess: (data, variables) => {
        const cache = queryClient.getQueryData<GetUsersResponse>(queryKey);
        if (cache) {
          queryClient.setQueryData(queryKey, [
            ...cache,
            {
              userId: data.user.id,
              name: variables.data.name,
              email: variables.data.email,
              roleName: variables.data.role.label.toUpperCase(),
              inviteLink: data.inviteLink,
            },
          ]);
        }
        showAlert({
          type: AlertType.SUCCESS,
          message: 'User has been invited',
        });
      },
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Failed to invite user',
          message: `${error?.message || 'Request failed'}`,
        });
      },
    },
  );

  return mutation;
};
