import { useQuery } from 'react-query';

import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';

export const useGetRoles = () => {
  const client = createHttpClient();
  const getRoles = useQuery(QUERY_KEYS.ROLES.ROLE_LIST, () =>
    client<[{ id: number; role_name: string }]>({
      url: END_POINTS.ROLE.LIST_ROLES,
    }),
  );

  return getRoles;
};
