import { FC, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { Trash } from '@assets/icons';
import { Button, Select, SelectTypeRefComponent } from '@components/index';
import { userProfile } from '@store/atoms/user';

import {
  DATA_HEADERS,
  MANAGE_TEAM_TABLE_HEADERS,
  manageTeamConstants,
} from '../constants';
import AddUserModal from './AddUserModal';
import { useDeleteUser } from '../hooks/use-delete-user';
import { useUpdateRole } from '../hooks/use-update-role';
import { ManageTeamTableProps, Role, UserDetails } from '../types';
import { CopyInviteLink } from './CopyInviteUserLink';

const UserRow: FC<{ user: UserDetails; roleList: Role[] }> = ({
  user,
  roleList,
}) => {
  const currentUser = useRecoilValue(userProfile);

  const [selectedRole, setSelectedRole] = useState(() => {
    return roleList?.find(
      role => role?.label.toLowerCase() === user?.roleName.toLowerCase(),
    );
  });

  const { mutateAsync: updateRole } = useUpdateRole();
  const { mutate: deleteUser } = useDeleteUser();

  const isCurrentUser = user.userId === currentUser.id;

  const onOptionSelect = (newRole: Role) => {
    updateRole({
      roleId: newRole.id,
      userId: user.userId,
      onRoleUpdate: () => setSelectedRole(newRole),
    });
  };

  const onDeleteUser = () => {
    deleteUser(user.userId);
  };

  const renderColumn = (field: string) => {
    switch (field) {
      case manageTeamConstants.roleName:
        return (
          <Select
            options={roleList}
            selectedOption={selectedRole}
            onOptionSelect={onOptionSelect}
            placeHolder="Select role"
            PopperRefComponent={SelectTypeRefComponent}
            classOverride="min-w-[72px] max-h-[215px]"
          />
        );
      case manageTeamConstants.inviteLink:
        return user.inviteLink ? (
          <CopyInviteLink inviteLink={user.inviteLink} />
        ) : (
          '-'
        );
      default:
        return user[field as keyof UserDetails] || '-';
    }
  };

  return (
    <tr className="h-16 border-gray-6 text-md border-b font-medium">
      {DATA_HEADERS.map(field => (
        <td
          key={field}
          className="border-r px-6 py-4 border-gray-6 break-words"
        >
          {renderColumn(field)}
        </td>
      ))}
      <td className="border-r px-6 py-4 border-gray-6 break-words">
        <div className="flex items-center justify-center size-full">
          <Trash
            className={`size-6 ${isCurrentUser ? 'opacity-25' : ''}`}
            onClick={!isCurrentUser ? onDeleteUser : undefined}
          />
        </div>
      </td>
    </tr>
  );
};

const ManageTeamTable: FC<ManageTeamTableProps> = ({ users, roleList }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="flex flex-col px-8 py-6 overflow-auto h-[calc(100vh-122px)]">
        <div className="border border-gray-6 rounded-t-lg rounded-tr-lg px-8 py-6 text-md">
          <div className="flex justify-between items-center">
            <span className="text-gray-3">
              Showing {users?.length} team member{users.length > 1 && 's'}
            </span>
            <Button
              label="Add new user"
              classOverride="!w-[180px] !font bold"
              variant="contained"
              onClick={() => setIsOpen(true)}
            />
          </div>
        </div>
        <div className="border-l border-r border-gray-6">
          <table className="table-fixed min-w-full border-collapse">
            <thead className="border-b border-gray-6">
              <tr>
                {MANAGE_TEAM_TABLE_HEADERS.map(item => (
                  <th
                    key={item}
                    className="px-6 py-2 border-r border-b border-gray-6 text-left w-[20%]"
                  >
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white">
              {users?.length ? (
                users.map(user => (
                  <UserRow
                    key={user.userId}
                    user={user}
                    roleList={roleList as Role[]}
                  />
                ))
              ) : (
                <tr className="h-16 border-gray-6 text-md border-b font-medium">
                  <td colSpan={5} className="text-center py-4">
                    No user available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {isOpen && (
          <AddUserModal
            isOpen={isOpen}
            onCloseModal={() => setIsOpen(false)}
            roleList={roleList || []}
          />
        )}
      </div>
    </>
  );
};

export default ManageTeamTable;
