import { useMutation, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';
import { workspace } from '@store/atoms/workspace';

import { GetUsersResponse } from '../types';

export const useDeleteUser = () => {
  const currentWorkspace = useRecoilValue(workspace);

  const client = createHttpClient();
  const queryClient = useQueryClient();

  const queryKey = QUERY_KEYS.USER.USER_LIST;

  const mutation = useMutation(
    (userId: number) =>
      client({
        method: HttpMethod.POST,
        url: END_POINTS.USER.DELETE_USER(userId, currentWorkspace.id),
      }),

    {
      onSuccess: (_, variable) => {
        const cache = queryClient.getQueryData<GetUsersResponse>(queryKey);
        if (cache) {
          const updateUsers = cache.filter(user => user.userId !== variable);
          queryClient.setQueryData(queryKey, [...updateUsers]);
        }

        showAlert({
          type: AlertType.SUCCESS,
          message: 'User removed from workspace',
        });
      },
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          message: `${error?.message || 'Failed to delete user'}`,
        });
      },
    },
  );

  return mutation;
};
