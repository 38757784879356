import { FC, useState } from 'react';

import { isNil } from 'lodash';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import {
  Button,
  Input,
  Modal,
  Select,
  SelectTypeRefComponent,
} from '@components/index';
import { InputTypes } from '@enums/input';
import { workspace } from '@store/atoms/workspace';

import { useInviteUser } from '../hooks/use-invite-user';
import { AddUserModalProps, InviteUserRequestData, Role } from '../types';

const AddUserModal: FC<AddUserModalProps> = ({
  isOpen,
  onCloseModal,
  roleList,
}) => {
  const currentWorkspace = useRecoilValue(workspace);

  const [selectedOption, setSelectedOption] = useState<Role | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isValid },
  } = useForm<InviteUserRequestData>({
    mode: 'onChange',
  });

  const { mutate: inviteUser } = useInviteUser();

  const onFormSubmit = (data: InviteUserRequestData) => {
    inviteUser({ data, workspaceId: currentWorkspace.id });
    onCloseModal();
  };

  const onOptionSelect = (option: any) => {
    setSelectedOption(option);
    setValue('role', option);
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <div className="p-8 w-[348px] flex flex-col rounded-lg">
        <label className="text-lg font-bold text-black">Invite User</label>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="flex flex-col py-2 gap-2">
            <Input
              id="name"
              placeholder="Enter your name"
              label="Name"
              {...register('name', {
                required: {
                  value: true,
                  message: 'Please enter a name',
                },
                pattern: {
                  value: /^[A-Za-z ,.'-]+$/,
                  message: 'Only letters a-z (case insensitive) are allowed',
                },
              })}
              type={InputTypes.TEXT}
              customInputClass="focus:border-primary focus:bg-lightest"
              error={isNil(errors.name?.message) ? '' : errors.name.message}
            />
            <Input
              id="email"
              placeholder="Enter your email ID"
              label="Email"
              {...register('email', {
                required: {
                  value: true,
                  message: 'Please enter an email address',
                },
              })}
              type={InputTypes.EMAIL}
              customInputClass="focus:border-primary focus:bg-lightest"
              error={isNil(errors.email?.message) ? '' : errors.email.message}
            />
            <div className="flex flex-col">
              <label className="text-sm mb-2 mt-1">Role</label>
              <Select
                options={roleList as Role[]}
                selectedOption={selectedOption as Role}
                onOptionSelect={onOptionSelect}
                placeHolder="Select role"
                PopperRefComponent={SelectTypeRefComponent}
                classOverride="min-w-[200px] max-h-[215px]"
              />
            </div>
          </div>
          <Button
            label="Send Invite"
            type="submit"
            classOverride="font-bold mt-[7px]"
            variant="contained"
            isLoading={isSubmitting}
            isDisabled={isNil(selectedOption) || !isValid || isSubmitting}
          />
        </form>
      </div>
    </Modal>
  );
};

export default AddUserModal;
